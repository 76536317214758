<template lang="pug">
  div.row
    div.col-12
      div.row.g-3.align-items-center
        div.col-auto
          button.btn.btn-primary(type="button", @click="get(true)") 更新
          span.ms-3 データ取得:{{ moment(fetched).format('MM/DD HH:mm') }}
        div.col-auto
          div.form-check.form-switch.ms-3
            input.form-check-input#show-summary-switch(type="checkbox", v-model="show_summary", @change="get(true)")
            label.form-check-label.ms-2(for="show-summary-switch") 統計情報を表示
        div.col
          div.form-check.form-switch.ms-3
            input.form-check-input#show-del-switch(type="checkbox", v-model="show_dels", @change="get(true)")
            label.form-check-label.ms-2(for="show-del-switch") 削除済みも表示
        div.col-auto
          label.col-form-label 統計情報
        div.col-auto
          flatPickr.form-control(v-model="start_datetime", :config="datetime_config")
        div.col-auto
          label.col-form-label →
        div.col-auto
          flatPickr.form-control(v-model="end_datetime", :config="datetime_config")
        div.col-auto
          button.btn.btn-outline-secondary(type="button", @click="start_datetime=null;end_datetime=null") リセット


    div.col-12.mt-2
      VueGoodTable.targetv2(:key="reset_count",
                            :columns="columns",
                            :rows="rows",
                            :pagination-options="pagination_options",
                            styleClass="vgt-table striped condensed",
                            :sort-options="sort_options",
                            :row-style-class="rowStyleClassFn",
                            @on-column-filter="onColumnFilter",
                            @on-sort-change="onSortChange",
                            :fixed-header="true",
                            max-height="80vh",
                            :line-numbers="true")
        //- Header
        template(slot="table-column", slot-scope="props")
          span(v-if="props.column.field == 'u'")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
          span(v-else-if="props.column.field == 'm'")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
          span(v-else-if="props.column.field == 'url'")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
          span(v-else-if="props.column.field == 's'")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
          span(v-else-if="props.column.field == 'i'")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
          span(v-else)
            | {{ props.column.label }}
        //- Row
        template(slot="table-row", slot-scope="props")
          span(v-if="props.column.field == 'v'")
            <svg v-if="props.row.v" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'u'")
            router-link(:to="{ path:'user', query:{ id:props.row.u } }") {{ props.row.u }}
          span(v-else-if="props.column.field == 'l'")
            router-link(:to="{ path:'target2', query:{ id:props.row.id } }", target="_blank") {{ props.row.l? props.row.l:props.row.url }}
          span(v-else-if="props.column.field == 'url'")
            a(:href="props.row.url", target="_blank", rel="noopener")
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
          span(v-else-if="props.column.field == 's'")
            <svg v-if="props.row.s" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
          //- span(v-else-if="props.column.field == 'ner'")
          //-   | {{ props.row.ner? (props.row.ner=='0.00'? '':props.row.ner+'%'):"" }}
          //- span(v-else-if="props.column.field == 'er'")
          //-   | {{ props.row.er? (props.row.er=='0.00'? '':props.row.er+'%'):"" }}
          //- span(v-else-if="props.column.field == 'to'")
          //-   | {{ props.row.to? (props.row.to=='0.00'? '':props.row.to+'%'):"" }}
          span(v-else-if="props.column.field == 'ner' || props.column.field == 'er' || props.column.field == 'to'")
            | {{ props.formattedRow[props.column.field]!="0.00"? props.formattedRow[props.column.field]:"" }}
          span(v-else-if="props.column.field == 'avg'")
            | {{ props.formattedRow[props.column.field] }}{{ props.row.avg? "s":"" }}
          span(v-else-if="props.column.field == 'e'", :style="props.row.e!==props.row.c? { color:'rgb(220,53,69)', fontWeight:'bold' }:{}")
            | {{ props.formattedRow[props.column.field] }}
          div(v-else-if="props.column.field == 'action'")
            button.btn.btn-sm.btn-outline-secondary.px-1.py-0(v-if="!props.row.d", type="button", @click="resume_manual(props.row)") Node
          div(v-else-if="props.column.field == 'lo'")
            <svg v-if="props.row.lo!='none'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          div(v-else-if="props.column.field == 'a'")
            <svg v-if="props.row.a>0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          div(v-else-if="props.column.field == 't'")
            <svg v-if="props.row.t===5" viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="feather"><rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect><line x1="8" y1="21" x2="16" y2="21"></line><line x1="12" y1="17" x2="12" y2="21"></line></svg>
          div(v-else-if="props.column.field == 'st'")
            <svg v-if="props.row.st==4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          div(v-else-if="props.column.field == 'p'")
            <svg v-if="props.row.p" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/><path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/></svg>
          span(v-else)
            | {{ props.formattedRow[props.column.field] }}

</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';


export default {
  name:"TargetsV2",
  components:{
    VueGoodTable, flatPickr
  },
  watch: {
    $route(to, from) {
      if (Object.keys(to.query).length===0){
        this.sort_options.initialSortBy = { field:'id', type:'asc'};
        this.parse_query();
        this.reset_count++;
      }
    }
  },
  head:{
    title:{ inner:"TargetV2 List" }
  },
  data (){
    return {
      moment:moment,
      reset_count:0,
      pagination_options: {
        enabled: true,
        perPage: 200,
        perPageDropdown: [100, 200, 400]
      },
      sort_options:{
        enabled:true,
        initialSortBy:{field:'id', type:'asc'}
      },
      columns: [
        {
          label: '監視中',
          field: 'v',
          width: '85px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterDropdownItems: [ { value: true, text: '監視中' }, { value: false, text: '停止' } ]},
        },
        {
          label: 'ID',
          field: 'id',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'User',
          field: 'u',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'No',
          field: 'n',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'G',
          field: 'g',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'Monitor',
          field: 'm',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'Label',
          field: 'l',
          sortable: false,
          filterOptions: { enabled: true, trigger: 'enter' },
        },
        {
          label: 'URL',
          field: 'url',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          filterOptions: { enabled: true, trigger: 'enter' },
        },
        {
          label: 'SS',
          field: 's',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterDropdownItems: [ { value: true, text: '利用中' }, { value: false, text: 'なし' } ] },
        },
        {
          label: 'Interval',
          field: 'i',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterFn: this.columnFilterFn },
        },
        {
          label: 'Err',
          field: 'e',
          width: '60px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center',
          // sortable: false,
          type: 'date',
          dateInputFormat: 't', // timestamp
          dateOutputFormat: 'HH:mm', // LL/dd HH:mm
        },
        {
          label: 'At',
          field: 'c',
          width: '100px',
          thClass: 'text-center font-sm',
          tdClass: 'text-center font-sm',
          // sortable: false,
          type: 'date',
          dateInputFormat: 't', // timestamp
          dateOutputFormat: 'M/d HH:mm', // LL/dd HH:mm
        },
        {
          label: 'Login',
          field: 'lo',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Actions',
          field: 'a',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Type',
          field: 't',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Static',
          field: 'st',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'Proxy',
          field: 'p',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'NE%',
          field: 'ner',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterFn: this.columnFilterFn },
        },
        {
          label: 'Err%',
          field: 'er',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterFn: this.columnFilterFn },
        },
        {
          label: 'TO%',
          field: 'to',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterFn: this.columnFilterFn },
        },
        {
          label: 'Proc\nTime',
          field: 'avg',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterFn: this.columnFilterFn },
        },
        {
          label: 'Check\nCount',
          field: 'cc',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          // filterOptions: { enabled: true },
        },
        {
          label: 'C%',
          field: 'crate',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterFn: this.columnFilterFn },
        },
        {
          label: 'Action',
          field: 'action',
          width: '65px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          // filterOptions: { enabled: true },
        },
      ],
      start_datetime:null,
      end_datetime:null,
      datetime_config:{
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr:true
      },
      rows: [],
      data_fetched_at:null,
      show_dels:false,
      show_summary:false,
      user_id:"",
      query_parsed:false,
    }
  },
  computed :{
    fetched (){
      return this.$store.state.targets.fetched;
    }
  },
  created (){
    this.parse_query();
},
  async mounted (){
    this.rows = this.$store.state.targets.targets;
    this.start_datetime = this.$store.state.targets.start;
    this.end_datetime = this.$store.state.targets.end;
    await this.get(false);
  },
  methods: {
    parse_query (){
      if ("v" in this.$route.query)
        this.columns[0].filterOptions.filterValue = this.$route.query.v;
      if ("id" in this.$route.query)
        this.columns[1].filterOptions.filterValue = this.$route.query.id;
      if ("u" in this.$route.query){
        this.user_id = this.$route.query.u;
        this.columns[2].filterOptions.filterValue = this.$route.query.u;
      }
      if ("n" in this.$route.query)
        this.columns[3].filterOptions.filterValue = this.$route.query.n;
      if ("m" in this.$route.query)
        this.columns[4].filterOptions.filterValue = this.$route.query.m;
      if ("l" in this.$route.query)
        this.columns[5].filterOptions.filterValue = this.$route.query.l;
      if ("url" in this.$route.query)
        this.columns[6].filterOptions.filterValue = this.$route.query.url;
      if ("s" in this.$route.query)
        this.columns[7].filterOptions.filterValue = this.$route.query.s;
      if ("i" in this.$route.query)
        this.columns[8].filterOptions.filterValue = this.$route.query.i;
      if ("ner" in this.$route.query)
        this.columns[11].filterOptions.filterValue = this.$route.query.ner;
      if ("er" in this.$route.query)
        this.columns[12].filterOptions.filterValue = this.$route.query.er;
      if ("to" in this.$route.query)
        this.columns[13].filterOptions.filterValue = this.$route.query.to;
      if ("avg" in this.$route.query)
        this.columns[14].filterOptions.filterValue = this.$route.query.avg;
      if ("sort" in this.$route.query){
        if(typeof(this.$route.query.sort)=="string")
          this.sort_options.initialSortBy = [ { field:this.$route.query.sort.split("_")[0], type:this.$route.query.sort.split("_")[1] } ]
        else
          this.sort_options.initialSortBy = this.$route.query.sort.reduce((r,e)=>{ r.push({ field:e.split("_")[0], type:e.split("_")[1] }); return r; }, []);
      }
      if (this.$route.query.del==="true")
        this.show_dels = true;
      else
        this.show_dels = false;
      if (this.$route.query.summary==="true")
        this.show_summary = true;
      else
        this.show_summary = false;
    },
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    async get (force){
      if (!force && (Date.now() - this.fetched)<=600000000)
        return;

      this.loading();
      let res = null;
      let qs = [];
      if (this.show_dels)
        qs.push("dels");
      if (this.show_summary)
        qs.push("summary");
      res = await this.axios.post("/api/v2/targets?"+qs.join("&"), { start:this.start_datetime, end:this.end_datetime });
      if (this.query_parsed){
        this.$router.push({query:{ ...this.$route.query, del:this.show_dels, summary:this.show_summary }});
      }
      this.rows = res.data.targets;
      this.start_datetime = res.data.startend.start;
      this.end_datetime = res.data.startend.end;
      this.$store.commit("targets_fetched", Date.now());
      this.$store.commit("targets_targets", res.data.targets);
      this.$store.commit("targets_startend", res.data.startend);
      this.query_parsed = true;
      this.$swal.close();
    },
    rowStyleClassFn (row) {
      return row.d? 'del-row':'';
    },
    onColumnFilter (params){
      if (this.query_parsed){
        let f = {};
        if(this.$route.query.sort)
          f.sort = this.$route.query.sort;
        for (let [key, value] of Object.entries(params.columnFilters)) {
          if (value)
            f[key] = value;
        }
        this.$router.push({ query:f });
      }
    },
    onSortChange (params){
      if (this.query_parsed)
        this.$router.push({ query:{ ...this.$route.query, sort:params.reduce((r,e)=>{ r.push(`${e.field}_${e.type}`); return r; }, []) } });
    },
    columnFilterFn (data, filterString){
      if (!data){
        return false;
      }
      let threshold = 0;
      if (filterString.includes(">=")){
        threshold = parseFloat(filterString.replace(">=",""))
        return parseFloat(data)>=threshold;
      }
      else if (filterString.includes(">")){
        threshold = parseFloat(filterString.replace(">",""))
        return parseFloat(data)>threshold;
      }
      else if (filterString.includes("<=")){
        threshold = parseFloat(filterString.replace("<=",""))
        return parseFloat(data)<=threshold;
      }
      else if (filterString.includes("<")){
        threshold = parseFloat(filterString.replace("<",""))
        return parseFloat(data)<threshold;
      }
      else{
        return data==filterString;
      }

    },
    async target_action (target){
      let val = null;
      if (target.v)
        val = await this.$swal("Action",{ buttons: { pause: "停止", node: "ノード変更" }});
      else
        val = await this.$swal("Action",{ buttons: { resume: "再開", node: "ノード指定" }});
      if (val=="pause")
        await this.pause(target);
      else if (val=="resume")
        await this.resume(target);
      else if (val=="node")
        await this.resume_manual(target);
    },
    async pause(target){
      try{
        this.loading();
        await this.axios.post("/api/v2/pause", { target_v2_id:target.id });
        target.v = false;
        target.m = null;
        this.$store.commit("targets_fetched", 0);
        this.$swal.close();
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async resume(target){
      try{
        this.loading();
        let res = await this.axios.post("/api/v2/resume", { target_v2_id:target.id });
        target.v = true;
        target.m = res.data.node_id;
        this.$store.commit("targets_fetched", 0);
        this.$swal.close();
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },
    async resume_manual(target){
      let val = await this.$swal({text:"Input NodeID", content:"input", buttons:[true,true]});
      
      var node_id = parseInt(val, 10);
      if (node_id){
      }
      else{
        console.log({node_id});
        await this.$swal("Error","Invalid NodeID","error");
        return;
      }

      try{
        this.loading();
        let res = await this.axios.post("/api/v2/resume", { target_v2_id:target.id, node_id:node_id, user_id:target.u });
        target.v = true;
        target.m = node_id;
        this.$store.commit("targets_fetched", 0);
        this.$swal.close();
      }
      catch(err){
        console.log({ err });
        await this.$swal("Error","","error");
      }
    },


  }
}
</script>

<style lang="scss" scoped>
.form-check.form-switch input[type="checkbox"] {
  transform: scale(1.3);
}

.targetv2 ::v-deep {
  .vgt-table {
    font-size: .85rem;
    thead th {
      font-size: 13px;
      vertical-align: middle;
      padding-right: .75em;
      &.filter-th {
        padding: .75em .5em .75em .5em;
      }
    }
    td {
      vertical-align: middle;
    }
    .del-row {
      opacity: .3;
    }
    .font-sm {
      font-size: .85rem;
    }
    .feather {
      width: 18px;
      height: 18px;
      vertical-align: text-bottom;
      margin-top: 1px;
    }
    tr:hover{
      background-color: #bfe2e9!important;
    }
    th.line-numbers {
      font-size: 13px;
      width: 55px;
    }
  }
}
</style>